import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardService } from './auth-guard.service'

const routes: Routes = [
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },
  { path: 'slideshow', loadChildren: './pages/intro/slideshow/slideshow.module#SlideshowPageModule' },
  { path: 'welcome', loadChildren: './pages/intro/welcome/welcome.module#WelcomePageModule' },
  {
    path: 'gps-permission',
    loadChildren: './pages/intro/gps-permission/gps-permission.module#GpsPermissionPageModule'
  },
  {
    path: 'push-permission',
    loadChildren: './pages/intro/push-permission/push-permission.module#PushPermissionPageModule'
  },
  { path: 'user-detail/:id', loadChildren: './pages/user-detail/user-detail.module#UserDetailPageModule' },
  { path: 'user-edit/:id', loadChildren: './pages/user-edit/user-edit.module#UserEditPageModule' },
  { path: 'iap', loadChildren: './pages/code/code.module#CodePageModule' },
  { path: 'taggo', loadChildren: './pages/taggo/taggo.module#TaggoPageModule' },
  { path: 'taggo/:code', loadChildren: './pages/taggo/taggo.module#TaggoPageModule' },
  { path: 'checklist-detail', loadChildren: './pages/checklist-detail/checklist-detail.module#ChecklistDetailPageModule' },
  { path: 'chat-list', loadChildren: './pages/chat-list/chat-list.module#ChatListPageModule' },
  { path: 'chat-detail/:udid', loadChildren: './pages/chat-detail/chat-detail.module#ChatDetailPageModule' },
  { path: 'video-list-offline', loadChildren: './pages/video-list-offline/video-list-offline.module#VideoListOfflinePageModule' },
  { path: 'video-offline/:id', loadChildren: './pages/video-detail-offline/video-detail-offline.module#VideoDetailOfflinePageModule' },
  { path: 'notification-list', loadChildren: './pages/notification-list/notification-list.module#NotificationListPageModule' },
  { path: 'user-detail-content', loadChildren: './pages/user-detail-content/user-detail-content.module#UserDetailContentPageModule' },
  { path: 'user-detail-files', loadChildren: './pages/user-detail-files/user-detail-files.module#UserDetailFilesPageModule' },
  { path: '', loadChildren: './pages/tabs/tabs.module#TabsPageModule', canLoad: [AuthGuardService] },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
