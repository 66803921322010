import { LatLngLiteral } from '@agm/core'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { from, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { UserAuthProvider } from '../auth/user-auth'
import { ApiPage } from './../models/api-page.models'
import { User } from './../models/user.model'
import { ApiPageManager } from './api-page-manager'
import { GeoService } from './geo.service'
import { SettingsService } from './settings.service'

export interface UserUpdate {
  id: number
  name?: string
  surname?: string
  email?: string
  cover?: File
  avatar?: File
  username?: string
  birth_date?: any
  camper_image?: File
  camper_name?: string
  camper_description?: string
  camper_type?: number
  coordinates?: any
  site?: string
}

@Injectable({
  providedIn: 'root'
})
export class UsersService extends ApiPageManager<User> {
  baseURL: string

  constructor(
    protected readonly http: HttpClient,
    private readonly settingsService: SettingsService,
    private readonly userAuth: UserAuthProvider<User>,
    private readonly geoService: GeoService

  ) {
    super(http)
    this.baseURL = this.settingsService.getSetting('baseUrl')
  }

  getUser(id: number): Observable<User> {
    const url = `${this.baseURL}/api/users/${id}/`

    return this.http.get<User>(url)
  }

  getUserList(userIDs?: Array<number>, coordinates?: string, search?: string): Observable<Array<Partial<User>>> {
    const emptyUserList = userIDs.length === 0
    if (emptyUserList && !coordinates) {
      return from([])
    }

    const paramParts = []
    if (!emptyUserList) {
      userIDs.forEach(userID => {
        paramParts.push(`id=${userID}`)
      })
    }
    if (search) {
      paramParts.push(`search=${search}`)
    }
    if (coordinates) {
      paramParts.push(`coordinates=${coordinates}`)
    }
    const params = paramParts.join('&')
    const url = `${this.baseURL}/api/users/?${params}`
    return this.getFirst(url)
  }

  getUserNearMe(search?: string) {
    let coordinates = ''
    return from(this.geoService.lastPosition).pipe(
      switchMap(lastPosition => {
        if (lastPosition) {
          coordinates = `SRID%3D4326%3BPOINT(${lastPosition.lng} ${lastPosition.lat})`
        }

        return this.getUserList([], coordinates, search)
      })
    )
  }

  getUserByUdid(udid: string): Observable<Partial<User>> {
    const url = `${this.baseURL}/api/users/`

    return this.http.get<ApiPage<Partial<User>>>(url, { params: { udid: udid } }).pipe(
      map(page => page.results),
      map(result => result[0])
    )
  }

  updateUser(user: UserUpdate): Observable<User> {
    const url = `${this.baseURL}/api/users/${user.id}/`

    const formData = new FormData()

    Object.keys(user).forEach(key => {
      if (user[key] !== undefined) {
        if (['cover', 'avatar', 'camper_image'].includes(key)) {
          formData.append(key, user[key], user[key].name)
        } else {
          formData.append(key, user[key])
        }
      }
    })
    return this.http.patch<User>(url, formData).pipe(
      tap(newUser => {
        this.userAuth.pushNewUserState({
          logged: true,
          has_permission: true,
          user: newUser
        })
      })
    )
  }

  blockUser(user: number): Observable<any> {
    const url = `${this.baseURL}/api/users/${user}/block/`

    return this.http.post<User>(url, {})
  }

  follow(user: number): Observable<any> {
    const url = `${this.baseURL}/api/users/${user}/follow/`

    return this.http.post<User>(url, {})
  }

  unfollow(user: number): Observable<any> {
    const url = `${this.baseURL}/api/users/${user}/unfollow/`

    return this.http.post<User>(url, {})
  }

  followed(user: number): Observable<any> {
    const url = `${this.baseURL}/api/users/${user}/followed/`

    return this.getFirst(url)
  }

  follower(user: number): Observable<any> {
    const url = `${this.baseURL}/api/users/${user}/follower/`

    return this.getFirst(url)
  }

  isFollowing(user: number, following: number): Observable<{ is_following: boolean }> {
    const url = `${this.baseURL}/api/users/${user}/is_following/?user_id=${following}`

    return this.http.get<{ is_following: boolean }>(url)
  }

  updateUserPosition(userId: number, coordinates: LatLngLiteral) {
    return this.updateUser({
      id: userId,
      coordinates: `SRID=4326;POINT (${coordinates.lng} ${coordinates.lat})`
    }).subscribe()
  }
}
