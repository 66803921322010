import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { User } from '../models/user.model'
import { UserAuthProvider } from './user-auth'

@Injectable()
export class AuthenticationGard implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly userAuthProvider: UserAuthProvider<User>
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userAuthProvider.userState.pipe(
            map(
                userState => {
                    if (!userState.logged) {
                        this.router.navigate(['login'])
                    }

                    return true
                }
            )
        )
    }
}
