import { Injectable } from '@angular/core'
import { Network } from '@ionic-native/network/ngx'
import { BehaviorSubject, Observable } from 'rxjs'
import { ToastController, Platform, AlertController } from '@ionic/angular'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

export enum ConnectionStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline)
  alert: HTMLIonAlertElement

  constructor(
    private readonly network: Network,
    private readonly alertController: AlertController,
    private readonly plt: Platform,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {
    this.plt.ready().then(() => {
      this.initializeNetworkEvents()
      const status = this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline
      this.status.next(status)
    })
  }

  public initializeNetworkEvents() {
    this.network.onDisconnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Online) {
        console.log('WE ARE OFFLINE')
        this.status.next(ConnectionStatus.Offline)
        this.alertNetworkOfflineStatus()
      }
    })

    this.network.onConnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Offline) {
        console.log('WE ARE ONLINE')
        if (this.alert) {
          this.alert.dismiss()
        }
        this.status.next(ConnectionStatus.Online)
      }
    })
  }

  private async alertNetworkOfflineStatus() {

    const connection = this.translateService.instant('Connessione Offline')
    const message = this.translateService.instant('Sei Offline. Continua per proseguire in modalità offline.')
    if (this.alert) {
      this.alert.dismiss()
    }
    this.alert = await this.alertController.create({
      header: connection,
      message: message,
      buttons: [
        {
          text: this.translateService.instant('Continua'),
          role: 'continue'
        }
      ]
    })
    this.alert.onDidDismiss().then(val => {
      if (val.role === 'continue') {
        this.router.navigate(['video-list-offline'])
      }
    })
    await this.alert.present()
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable()
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue()
  }
}
