import 'moment/locale/it'

import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Router } from '@angular/router'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { AlertController, Platform, ToastController } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import * as moment from 'moment'
import { combineLatest, of, Subscription } from 'rxjs'
import { distinctUntilKeyChanged, filter, map, take, delay } from 'rxjs/operators'

import { UserAuthProvider } from './auth/user-auth'
import { Push } from './models/push-models'
import { User, DeepLink } from './models/user.model'
import { GeoService } from './services/geo.service'
import { ConnectionStatus, NetworkService } from './services/network.service'
import { PushService } from './services/push.service'
import { SettingsService } from './services/settings.service'
import { UsersService } from './services/users.service'
import { Deeplinks } from '@ionic-native/deeplinks/ngx'
import { HttpClient } from '@angular/common/http'

import { AngularFireAuth } from '@angular/fire/auth'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  previusOpen: any
  deeplinkSubscription?: Subscription

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private readonly storage: Storage,
    private readonly userAuth: UserAuthProvider<User>,
    private readonly router: Router,
    private readonly toastCtrl: ToastController,
    private readonly geoService: GeoService,
    private readonly pushService: PushService,
    private readonly settingsService: SettingsService,
    private readonly networkService: NetworkService,
    private readonly userService: UsersService,
    private readonly alertController: AlertController,
    private readonly http: HttpClient,
    private readonly deeplinks: Deeplinks,
    private afAuth: AngularFireAuth,
  ) {
    this.initializeApp()
    this.userAuth.setPermission(user => of(user.is_active))
    this.createAnonymousUser()
    this.checkForFirstOpen()
    moment.locale('it')
    this.settingsService.currentAppConfig$.subscribe(settings => {
      console.log('Nuovo App Settings', settings)
      const root = document.documentElement
      root.style.setProperty('--ion-color-primary', settings && settings.partner && settings.partner.primary_color)
      root.style.setProperty('--ion-color-secondary', settings && settings.partner && settings.partner.secondary_color)
      root.style.setProperty('--default-content-background', settings && settings.partner && settings.partner.background_color)
    })
  }

  async checkForFirstOpen() {
    if (await this.storage.get('opened')) {
      console.log('Checking user...')
      let userIsLogged
      this.userAuth.userState.subscribe(
        (value) => {
          userIsLogged = value.logged
          console.log('User is logged? ', userIsLogged)
          if (userIsLogged != true) { this.router.navigate(['login']) } else if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            this.router.navigate(['video-list-offline'])
          } else {
            this.geoService.initGeolocation()
            const userLogged$ = this.userAuth.userState.pipe(
              filter(state => state.logged),
              map(state => state.user),
              distinctUntilKeyChanged('id'),
            )
            const position$ = this.geoService.position$.pipe(
              take(1)
            )
            combineLatest(
              userLogged$,
              position$
            ).pipe(delay(60000)).subscribe(
              results => {
                const user = results[0]
                const coordinates = results[1]
                this.userService.updateUserPosition(user.id, coordinates)
              }
            )
            console.log('Init mobile push')
            this.pushService.initMobile()
          }
        }
      )
    } else {
      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
        this.router.navigate(['login'])
      } else {
        this.storage.set('opened', true)
        this.router.navigate(['slideshow'])
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleBlackTranslucent()
      // window.originalOpen è stato impostato in index.html con il valore iniziale di window.open prima dell'attivazione di InAppBrowser
      window.open = (window as any).originalOpen

      if ((this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
        this.subscribeForDeeplink()
      }
    })
  }

  subscribeForDeeplink() {
    console.log('subscribeForDeeplink')

    if (this.deeplinkSubscription) {
      this.deeplinkSubscription.unsubscribe()
    }
    this.deeplinkSubscription = this.deeplinks.route({}).subscribe((match) => {
      console.log('Strano. Successfully matched route', match)
    }, (nomatch) => {
      // nomatch.$link - the full link data
      console.log('nomatch', nomatch)
      if (nomatch && nomatch['$link']) {
        const link: DeepLink = nomatch['$link']
        if (link.host && link.host === 'web.camperinfinity.app' && link.path) {
          this.router.navigateByUrl(link.path).then(() => {
            this.subscribeForDeeplink()
          })
        } else if (link.host && link.host === 'camperinfinity.page.link' && link.path) {
          const code = link.path.replace('/', '')
          if (code !== '') {
            this.http.get<{ deepLinkTarget: string }>(
              'https://us-central1-campergo-538c2.cloudfunctions.net/resolveDeepLink',
              { params: { code } }).subscribe(result => {
                const path = result.deepLinkTarget.split('web.camperinfinity.app')[1]

                if (path) {
                  this.router.navigateByUrl(path).then(() => {
                    this.subscribeForDeeplink()
                  })
                } else {
                  // TODO: controllare se commentando risolve il loop su web e funziona su mobile
                  // this.subscribeForDeeplink()
                }
              }, err => {
                console.error(err)
              })
          } else {
            const url = new URL(link.url)
            const linkParam = url.searchParams.get('link')
            let path = decodeURIComponent(linkParam)
            path = path.split('web.camperinfinity.app')[1]
            if (path) {
              this.router.navigateByUrl(path).then(() => {
                this.subscribeForDeeplink()
              })
            }

          }
        }
      }
    }, () => { console.log('complete deeplink') })
  }

  async showError(message): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom',
      translucent: true
    })
    toast.present()
  }

  async createAnonymousUser(): Promise<void> {
    console.log(environment.firebase.apiKey)
    this.afAuth.auth.signInAnonymously().then(
      (userData) => {
        console.log('Firebase UID: ' + userData.user.uid)
        // alert(environment.firebase.apiKey + '\nFirebase UID: ' + userData.user.uid)
      }
    ).catch(err => {
      if (err) {
        console.error(err)
        // alert(environment.firebase.apiKey + '\n' + err)
      }
    })
  }
}
