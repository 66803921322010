import { Injectable } from '@angular/core';
import { UserAuthProvider } from 'src/app/auth/user-auth'
import { User } from 'src/app/models/user.model'
import { Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor(
    private userAuth: UserAuthProvider<User>,
    private router: Router
  ) {}

  canLoad(route: Route): Observable<boolean>{
    console.log(route)
    return this.userAuth.userState.pipe(
      take(1),
      map(user => user.logged)
    )
  }
}
