import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core'
import { IonicStorageModule, Storage } from '@ionic/storage'

import { UserAuthProvider } from './user-auth'
import { AuthenticationGard } from './user-auth-guard'
import { UserAuthInterceptor } from './user-auth-interceptor'
import { UserAuthConfig } from './user-auth-models'

export function userAuthFactory(http: HttpClient, storage: Storage, config: UserAuthConfig): UserAuthProvider<{}> {
    return new UserAuthProvider(http, storage, config)
}

export const UserAuthConfigToken = new InjectionToken<UserAuthConfig>('UserAuthConfigToken')

@NgModule({
    declarations: [

    ],
    imports: [
        IonicStorageModule.forRoot()
    ],
    providers: [
        {
            provide: UserAuthProvider,
            useFactory: userAuthFactory,
            deps: [HttpClient, Storage, UserAuthConfigToken]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserAuthInterceptor,
            multi: true
        },
        AuthenticationGard
    ]

})
export class UserAuthModule {
    static forRoot(config?: UserAuthConfig): ModuleWithProviders {
        return {
            ngModule: UserAuthModule,
            providers: [
                { provide: UserAuthConfigToken, useValue: config }
            ]
        }
    }
}
