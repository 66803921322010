import { AgmCoreModule } from '@agm/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { AnalyticsFirebase } from '@ionic-native/analytics-firebase/ngx'
import { Facebook } from '@ionic-native/facebook/ngx'
import { File } from '@ionic-native/file/ngx'
import { Geolocation } from '@ionic-native/geolocation/ngx'
import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx'
import { WebView } from '@ionic-native/ionic-webview/ngx'
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx'
import { LaunchReview } from '@ionic-native/launch-review/ngx'
import { Network } from '@ionic-native/network/ngx'
import { OneSignal } from '@ionic-native/onesignal/ngx'
import { QRScanner } from '@ionic-native/qr-scanner/ngx'
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslatePoHttpLoader } from '@fjnr/ngx-translate-po-http-loader';
import { Device } from '@ionic-native/device/ngx'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { UserAuthModule } from './auth/user-auth.module'
import { I18nModule } from './i18n/i18n.module'
import { Deeplinks } from '@ionic-native/deeplinks/ngx'

import { SignInWithApple, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx'
import { AngularFireAuthModule } from '@angular/fire/auth'

// for AoT compilation
export function translateFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslatePoHttpLoader(http, '../assets/i18n', '.po');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDysyBN-Vefz9zEvwiffyFBXoRGDYFtI1U',
      libraries: ['places', '']
    }),
    UserAuthModule.forRoot({
      baseURL: 'https://app.campergo.eu',
      authPath: `https://app.campergo.eu/o/token/`,      // path all'api del token
      userPath: `https://app.campergo.eu/api/users/`,    // path all'api dello user
      userInstancePath: `https://app.campergo.eu/api/users/me/`,    // path all'api dello user
      clientID: 'scggtb8odguuY56HXo8CEnN1T6jpGl4SREYM8v38',
      // tslint:disable-next-line:max-line-length
      clientSecret: 'xfR1NOca7ZG31VrUjmQZuMnFaVimpm1Z74Pj4xDRny7iyMJhtv5kK96HdtJiGRhPG5YZIbJxQfYnCiaGKh6Gkjb1zi4CcIZ2WUwW9bFslrtOVQiASwLDuQG8CDzEqOKU',
      facebookPath: `https://app.campergo.eu/api/social-login/facebook/`,
      googlePath: `https://app.campergo.eu/api/social-login/google/`,
      applePath: `https://app.campergo.eu/api/social-login/apple/`
    }),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    I18nModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    OneSignal,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SocialSharing,
    GooglePlus,
    Facebook,
    Geolocation,
    InAppPurchase2,
    File,
    WebView,
    LaunchReview,
    QRScanner,
    Network,
    LaunchNavigator,
    InAppBrowser,
    AnalyticsFirebase,
    Deeplinks,
    SignInWithApple, 
    ASAuthorizationAppleIDRequest,
    Device
  ],
  bootstrap: [AppComponent],
  exports: [
    I18nModule,
  ]
})
export class AppModule { }
