import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { OneSignal, OSNotification, OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx'
import { AlertController } from '@ionic/angular'
import { Subject } from 'rxjs'

import { Push, PushType } from '../models/push-models'
import { SettingsService } from './settings.service'

@Injectable({
    providedIn: 'root'
})
export class PushService {

    private isCordova: boolean

    constructor(
        private readonly oneSignal: OneSignal,
        private readonly settings: SettingsService,
        private readonly alertController: AlertController,
        private readonly router: Router) {

    }

    initMobile(): void {
        this.isCordova = true
        // this.oneSignal.setLogLevel({ logLevel: 6, visualLevel: 3 })
        this.oneSignal.startInit(this.settings.getString('oneSignalAppID'))

        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification)

        this.oneSignal.handleNotificationReceived().subscribe((notification: OSNotification) => {
            return
        })

        this.oneSignal.handleNotificationOpened().subscribe((notification: OSNotificationOpenedResult) => {
            const payload = notification.notification.payload
            if (notification.notification.isAndroid) {
                this.oneSignal.cancelNotification(`${notification.notification.androidNotificationId}`)
            }
            const push_type = payload.additionalData && payload.additionalData.push_type
            console.log('Push type')
            console.log(push_type)

            switch (Number(push_type)) {
                case PushType.MESSAGE:
                    this.router.navigate(['/', 'chat-detail', payload.additionalData.chat_udid])
                    break
                case PushType.REMINDER:
                    this.router.navigate(['/', 'tabs', 'profile', 'reminders'])
                    break
                case PushType.WEGO:
                    this.router.navigate(['/', 'tabs', 'we-go', 'we-go-detail', payload.additionalData.id])
                    break
                case PushType.VIDEO:
                    this.router.navigate(['/', 'tabs', 'video', payload.additionalData.id])
                    break
                case PushType.LEVEL_UP:
                    this.showDialog({ text: payload.body, title: payload.title })
                    break
                default:
                    this.router.navigate(['/notification-list'])
                    break
            }
        })
        this.oneSignal.endInit()
    }

    showDialog(push: Push) {
        this.alertController.create({
            cssClass: 'vgame-award-alert',
            header: push.title,
            message: `<div class="vgame-award">
                  <ion-icon src="/assets/icon/vcoin_award.svg"></ion-icon>
                  <p>${push.text}</p>
                  </div>`,
            buttons: ['OK']
        }).then(alert => alert.present())
    }

    sendPush(keys: Array<number>, heading, text, data): Promise<any> {
        if (!this.isCordova) {
            return Promise.resolve({})
        }

        const filters = []
        for (const key of keys) {
            filters.push({
                field: 'tag',
                key: 'id',
                relation: '=',
                value: key
            })
            filters.push({ operator: 'OR' })
        }
        filters.pop()

        const notificationObj = {
            filters,
            headings: {
                en: heading
            },
            contents: {
                en: text
            },
            data,
            mutable_content: true,
            ios_badgeType: 'Increase',
            ios_badgeCount: 1
        }

        return this.oneSignal.postNotification(notificationObj)
    }
}
