import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, skip, tap, filter, map } from 'rxjs/operators'

import { AppConfig } from '../models/user.model'

class Settings {
  appName = 'Camper INFINITY'

  baseUrl = 'https://app.campergo.eu'

  clientId = ''

  // tslint:disable-next-line:max-line-length
  clientSecret = ''

  oneSignalAppID = '9180b990-198e-4e3f-a78c-266a199aad1f'

  webClientID = '792719335301-uhvckhekn8pivoq013gcc94hi0b5jmeg.apps.googleusercontent.com'

  googleProjectNumber = '121159353507'
  resetPasswordLink = 'https://app.camperinfinity.app/password_reset/'
  verifyPurchaseUrl = `${this.baseUrl}/api/purchase/verify/`
  androidPackageName = 'com.campergo.app'
  iosBundleId = 'eu.campergo.app'
  iosAppStoreId = '1459657501'
  firebaseDynamicLinkDomain = 'camperinfinity.page.link'
  webAppBaseUrl = 'https://web.camperinfinity.app'
  iapProductSKU = 'premiumsubscription'
  iapProductSKUAnnual = 'premiumannualsubscription'

  vimeoToken = 'f284b84f20b848b68bfcea24c7a664fa'
  mapsKeys = {
    API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyDysyBN-Vefz9zEvwiffyFBXoRGDYFtI1U',
    API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyDysyBN-Vefz9zEvwiffyFBXoRGDYFtI1U'
  }
}

type settingsKeys = keyof Settings

type WAITING = 'WAITING'

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly settings = new Settings()

  private mode: 'prod' | 'dev' = 'prod'
  private readonly WAITING = 'WAITING'

  private _currentAppConfig$ = new BehaviorSubject<AppConfig|WAITING>(this.WAITING)
  currentAppConfig: AppConfig

  currentAppConfig$ = this._currentAppConfig$.pipe(
    filter(x => x !== this.WAITING),
    map(x => x as AppConfig)
  )

  constructor(private readonly http: HttpClient, private readonly storage: Storage) {
    this.storage.get('appSettings').then((config: AppConfig) => {
      this.applyAppConfigIfValid(config)
    })

    this.getAppConfig().subscribe(() => {
      console.log('config arrivata')
    }, err => {
      console.log(err)
    })
  }

  private applyAppConfigIfValid(config: AppConfig) {
    if (config && config.expiration_date) {
      const expiration = new Date(config.expiration_date)
      expiration.setHours(23, 59, 59)
      if (expiration.getTime() > new Date().getTime()) {
        this.currentAppConfig = config
        this._currentAppConfig$.next(config)
      }
    }
  }

  getString(key: settingsKeys): string {
    return this.getSetting(key)
  }

  getSetting<T>(key: settingsKeys): T {
    const val = this.settings[key]
    if (val[this.mode]) {
      return val[this.mode] as T
    } else {
      return val as any
    }
  }

  resetAppConfig() {
    this.currentAppConfig = null
    this._currentAppConfig$.next(null)
  }

  deleteAppConfig(): Observable<any> {
    const baseURL = this.getSetting('baseUrl')
    const url = `${baseURL}/api/users/app_config/`

    return this.http.delete(url).pipe(
      tap(() => {
        this.resetAppConfig()
      })
    )
  }

  getAppConfig(): Observable<AppConfig> {
    const baseURL = this.getSetting('baseUrl')
    const url = `${baseURL}/api/users/app_config/`

    return this.http.get<AppConfig>(url).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status && (err.status === 401 || err.status === 404)) {
          return of(null)
        } else {
          throw err
        }
      }),
      tap(config => {
        if (config && config.expiration_date) {
          this.currentAppConfig = config
          this._currentAppConfig$.next(config)
        } else {
          this.resetAppConfig()
        }
      })
    )
  }
}
