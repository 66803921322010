enum platform {browser, android, ios}

const apiKey = ['AIzaSyAC55KbH6FmmN0sFCVqny67aQOIJjDl_ac',
                'AIzaSyCa-qoc5UJC1tHpey6ugf4AuXFab93Th1M',
                // 'AIzaSyCfyC38syb0IERJkLEiBYfT4HDfE1Bv7XI', //chiave Android senza restrizioni autogenerata da firebase
                'AIzaSyCB4doAugJvVJxAuCZNNVYbaJEXUXNtHuo']

export const environment = {
  production: false,
  firebase: {
    apiKey: apiKey[platform.browser],
    authDomain: 'campergo-538c2.firebaseapp.com',
    databaseURL: 'https://campergo-538c2.firebaseio.com',
    projectId: 'campergo-538c2',
    storageBucket: 'campergo-538c2.appspot.com',
    messagingSenderId: '792719335301'
  }
}