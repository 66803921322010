import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import {Observable, throwError } from 'rxjs'
import {catchError, flatMap, tap, timeout} from 'rxjs/operators'

import { UserAuthProvider } from './user-auth'
import { AuthToken } from './user-auth-models'
import {Router} from '@angular/router'

// import 'rxjs';
/*
  Generated class for the UserAuthProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class UserAuthInterceptor implements HttpInterceptor {

    private userAuthProvider: UserAuthProvider<any>

    constructor(private inj: Injector, private router: Router) {
        console.log('UserAuth-Interceptor init')
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now()
        if (this.userAuthProvider == null) {
            this.userAuthProvider = this.inj.get(UserAuthProvider)
        }

        if (this.userAuthProvider != null) {
            if (req.url.indexOf(this.userAuthProvider.config.authPath) > -1) {
                console.log(`${req.method} - ${req.urlWithParams} - no Token`)

                return next.handle(req).pipe(timeout(10000), catchError((res) => {
                    if (!res.status && res.status !== 0) {
                        this.router.navigate(['video-list-offline'])
                    }
                    return throwError(res)
                }))
            }

            return this.userAuthProvider.getAuthToken().pipe(

                flatMap((token: AuthToken) => {

                    const authToken = token

                    return token && token.access_token ?

                        this.handleCall(next, req, started, token).pipe(
                            timeout(10000),
                            catchError((res) => {
                                if (res.status) {
                                    const elapsed = Date.now() - started
                                    console.log(`${req.urlWithParams} -> ${res.status} ${res.statusText} in ${elapsed}ms`)
                                    if (res.status === 401) {
                                        console.warn(`### DETECT UNAUTHORIZED REQUEST --- 401 --- `)
                                        console.warn(`CALL: ${req.url} IN QUEUE`)
                                        if (authToken) {
                                            return this.userAuthProvider.syncronizeRefreshToken(authToken).pipe(
                                                flatMap(newAuthToken => {
                                                    console.warn(` |||||||| Retry with after refresh token -- for: ${req.url}`)

                                                    return this.handleCall(next, req, Date.now(), newAuthToken)
                                                }))
                                        } else {
                                            return this.handleCall(next, req, Date.now())
                                        }
                                    } else {
                                        return throwError(res)
                                    }
                                } else {
                                    if (res.status !== 0) {
                                        this.router.navigate(['video-list-offline'])
                                    }
                                    return throwError(res)
                                }
                            })
                        )

                        :
                        this.handleCall(next, req, started)
                }))
        } else {
            console.error(`CAN'T INJECT LOGINSERVICE IN TOKEN INTERCEPTOR`)

            return next.handle(req)
        }
    }

    // tslint:disable-next-line:prefer-function-over-method
    private handleCall(next: HttpHandler, req: HttpRequest<any>, started: number, authToken: AuthToken = null): Observable<HttpEvent<any>> {
        let newRequest
        if (authToken && authToken.access_token && req.url.includes(this.userAuthProvider.config.baseURL)) {
            newRequest = req.clone({
                headers: req.headers.append(
                    'Authorization',
                    `${authToken.token_type} ${authToken.access_token}`
                )
            })
            console.log(`${req.method} - ${req.urlWithParams} - ${authToken.access_token}`)
        } else {
            newRequest = req
            console.log(`${req.method} - ${req.urlWithParams} - no Token`)
        }

        return next.handle(newRequest).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const elapsed = Date.now() - started
                    console.log(`${req.urlWithParams} -> ${event.status} ${event.statusText} in ${elapsed}ms`)
                }
            })
        )
    }

}
