export enum PushType {
    MESSAGE = 0,
    REMINDER,
    LEVEL_UP,
    COIN,
    OTHER,
    WEGO,
    VIDEO
}
export interface Push {
    title: string,
    text: string
}
