import { CommonModule, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import localeEn from '@angular/common/locales/en'
import localeEs from '@angular/common/locales/es'
import localeDeExtra from '@angular/common/locales/extra/de'
import localeEnExtra from '@angular/common/locales/extra/en'
import localeEsExtra from '@angular/common/locales/extra/es'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeItExtra from '@angular/common/locales/extra/it'
import localeFr from '@angular/common/locales/fr'
import localeIt from '@angular/common/locales/it'
import { NgModule } from '@angular/core'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

import { AcceptLanguageHeader } from './i18n-interceptor'



@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AcceptLanguageHeader,
          multi: true
      }
  ],

  declarations: [],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(private readonly translate: TranslateService) {
    // the second parameter 'fr' is optional
    registerLocaleData(localeFr, 'fr-FR', localeFrExtra)
    registerLocaleData(localeIt, 'it-IT', localeItExtra)
    registerLocaleData(localeDe, 'de-DE', localeDeExtra)
    registerLocaleData(localeEn, 'en-GB', localeEnExtra)
    registerLocaleData(localeEs, 'es-Es', localeEsExtra)
    const browserLang = translate.getBrowserLang()
    this.translate.setDefaultLang(browserLang)
    
    console.log(`Attivo ${browserLang}`)
   }
 }
