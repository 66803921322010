import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

@Injectable()
export class AcceptLanguageHeader implements HttpInterceptor {
    translateService: TranslateService
    constructor(private injector: Injector) {
        this.translateService = this.injector.get(TranslateService)
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const locale = this.translateService.getBrowserCultureLang()
        const modified = req.clone({setHeaders: {'Accept-Language': locale}})
        return next.handle(modified)
    }
}
