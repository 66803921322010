import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { ApiPage } from '../models/api-page.models'

export class ApiPageManager<T> {
    private previousUrl: string | null
    private nextUrl: string | null
    private firstCall = true
    private params?: HttpParams | {[param: string]: string | Array<string>}

    constructor(@Inject(HttpClient) protected readonly http: HttpClient) { }

    get hasNext(): boolean {
        return this.nextUrl !== null
    }
    get hasPrevious(): boolean {
        return this.previousUrl !== null
    }

    protected getFirst(url: string, params?: HttpParams | {[param: string]: string | Array<string>}): Observable<Array<T>> {
        this.nextUrl = url
        this.previousUrl = null
        this.firstCall = true
        this.params = params

        return this.getNext()
    }

    getNext(): Observable<Array<T>> {
        if (!this.hasNext) {
            return of([])
        }

        return this.http.get<ApiPage<T>>(this.nextUrl, {params: this.params}).pipe(
            tap(page => {
                this.params = undefined
                this.nextUrl = page.next
                if (this.firstCall) {
                    this.previousUrl = page.previous
                }
                this.firstCall = false
            }),
            map(page => page.results)
        )
    }

    getPrevious(): Observable<Array<T>> {
        if (!this.hasPrevious) {
            return of([])
        }
        const url = this.firstCall ? this.nextUrl : this.previousUrl

        return this.http.get<ApiPage<T>>(url).pipe(
            tap(page => {
                this.previousUrl = page.previous
                if (this.firstCall) {
                    this.nextUrl = page.next
                }
                this.firstCall = false
            }),
            map(page => page.results)
        )
    }
}
