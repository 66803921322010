import { LatLngLiteral } from '@agm/core'
import { Injectable } from '@angular/core'
import { Geolocation } from '@ionic-native/geolocation/ngx'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  public position$: Observable<LatLngLiteral>
  positionSubject$: BehaviorSubject<LatLngLiteral> = new BehaviorSubject<LatLngLiteral>(null)
  constructor(
    private readonly geolocation: Geolocation,
    private readonly storage: Storage,
  ) {
    this.position$ = this.positionSubject$.pipe(
      filter(position => !!position)
    )

  }

  initGeolocation() {
    this.geolocation.getCurrentPosition({enableHighAccuracy: true}).then((resp) => {
      this.setCoordinates(resp)
    }).catch((error) => {
      console.log('Error getting location', error)
    })

    const watch = this.geolocation.watchPosition()
    watch.subscribe((data) => {
      this.setCoordinates(data)
    })

  }

  private setCoordinates(resp: any) {
    console.log('setting coords')
    console.log(resp)

    if (resp && resp.coords) {
      const lat = resp.coords.latitude
      const lng = resp.coords.longitude
      const latLng = {lat, lng}
      this.storage.set('latLon', latLng)
      this.positionSubject$.next(latLng)
    }

  }

  get lastPosition(): Promise<LatLngLiteral> {
    return this.storage.get('latLon')
  }
}
