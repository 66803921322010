export interface UserState<UserType> {
    logged: boolean
    has_permission: boolean
    user: UserType
}

export class UserAuthConfig {
    baseURL: string
    userPath: string
    userInstancePath: string
    authPath: string
    clientID: string
    clientSecret: string
    facebookPath: string
    googlePath: string
    applePath: string
}

export interface AuthToken {
    access_token?: string
    refresh_token?: string
    token_type?: string
    expires_in?: number
    expireTime?: number
    authorization?: string
}
